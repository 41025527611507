@tailwind base;
@tailwind components;
@tailwind utilities;

@import './antd.css';

html {
  font-size: 37.5px;
}

body {
  min-height: 100vh;
}

.overflow-ellipsis-base {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.overflow-ellipsis-2 {
  @apply overflow-ellipsis-base;
  -webkit-line-clamp: 2;
}

.overflow-ellipsis-3 {
  @apply overflow-ellipsis-base;

  -webkit-line-clamp: 3;
}

.overflow-ellipsis-4 {
  @apply overflow-ellipsis-base;

  -webkit-line-clamp: 4;
}

.overflow-ellipsis-10 {
  @apply overflow-ellipsis-base;

  -webkit-line-clamp: 10;
}

.bg-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.opacity-show {
  opacity: 1 !important;
}

.adm-ellipsis {
  white-space: pre-wrap;
  word-break: break-all;
}

.adm-ellipsis a{
  color: #FF9111;
}

@media screen and (min-width: 600px) {
  html {
    font-size: 58.4px !important;
  }

  .header {
    width: 375px !important;
    left: 50% !important;
    transform: translateX(-50%);
  }

  .pc-main {
    width: 375px !important;
    max-width: 460px;
    min-height: 100vh;
    margin: 0 auto;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.07);
    /* left: 50% !important;
    transform: translateX(-50%); */
  }

  .pc-main-w {
    width: 375px !important;
    margin: 0 auto;
    max-width: 460px;
  }

  .footer {
    width: 375px !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}