
.loading-icon 
  animation: adm-spin-loading-rotate .8s linear infinite

@keyframes adm-spin-loading-rotate
  0%
    transform: rotate(0deg)

  to
    transform: rotate(1turn)
